<template>
  <div class="">
    <section class="d-flex flex-wrap justify-center">
      <v-card class="my-4 mx-3 pr-5 cardBoxShadow rounded-lg w-40">
        <div class="pa-4">
          <div class="d-flex gap-15 w-25 justify-space-between">
            <v-btn height="20" class="fsize14" rounded color="green" dark>
              Send Payments
            </v-btn>
            <div>
              <div class="pr-3 d-flex gap-10 controlsRadio">
                <v-checkbox
                  v-model="paymentAutomatic"
                  :value="paymentAutomatic"
                  @click="handlePaymentSwitch('auto')"
                  class="controlsRadio"
                  label="Automatic"
                >
                </v-checkbox>
                <v-checkbox
                  v-model="paymentManual"
                  @click="handlePaymentSwitch('manual')"
                  :value="paymentManual"
                  class="controlsRadio"
                  label="Manual"
                >
                </v-checkbox>
              </div>
              <!-- <v-radio-group
              v-model="sendPaymentsType"
              row
              class="controlsRadio pa-0 my-0 ml-0 d-flex align-center"
            >
              <v-radio
                label="Automatic"
                name="active"
                value="automatic"
                class="controlsRadio"
              ></v-radio>
              <v-radio label="Manual" name="active" value="manual"></v-radio>
            </v-radio-group> -->
            </div>
          </div>
          <div class="h-180 py-3">
            <div class="d-flex gap-10">
              <p class="mt-1 subElementsWidth">ICCL</p>
              <v-switch
                v-model="icclSwitch"
                :disabled="paymentAutomatic"
                flat
                class="ma-0"
              ></v-switch>
              <!-- Check for both CDS and BCD -->
            </div>
            <div class="d-flex gap-10">
              <p class="mt-1 subElementsWidth">Back Office</p>
              <v-switch
                v-model="backOfficeSwitch"
                :disabled="paymentAutomatic"
                flat
                class="ma-0"
              ></v-switch>
              <!-- Check for both CDS and BCD -->
            </div>
            <div class="d-flex gap-10">
              <p class="mt-1 subElementsWidth">RMS</p>
              <v-switch
                v-model="rmsSwitch"
                :disabled="paymentAutomatic"
                flat
                class="ma-0"
              ></v-switch>
              <!-- Check for both CDS and BCD -->
            </div>
          </div>
          <div class="d-flex gap-10 justify-end pr-8">
            <v-btn
              :loading="loading"
              height="40"
              depressed
              type="submit"
              color="primary"
              class="fsize12 text-capitalize"
              @click="setPayments()"
              >Submit</v-btn
            >
          </div>
        </div>
      </v-card>
      <v-card class="my-4 mx-3 cardBoxShadow rounded-lg w-40">
        <div class="pa-4">
          <div class="d-flex gap-10 w-25 justify-space-between">
            <v-btn height="20" class="fsize14" rounded color="green" dark>
              Segment
            </v-btn>
            <div>
              <!-- <v-radio-group
              v-model="segmentType"
              row
              class="controlsRadio pa-0 my-0 ml-0 d-flex align-center"
            >
              <v-radio
                label="Automatic"
                name="active"
                value="automatic"
              ></v-radio>
              <v-radio label="Manual" name="active" value="manual"></v-radio>
            </v-radio-group> -->
              <div class="pr-3 d-flex gap-10 controlsRadio">
                <v-checkbox
                  v-model="segmentAutomatic"
                  :value="segmentAutomatic"
                  @click="handleSegmentSwitch('auto')"
                  class="controlsRadio"
                  label="Automatic"
                >
                </v-checkbox>
                <v-checkbox
                  v-model="segmentManual"
                  :value="segmentManual"
                  class="controlsRadio"
                  @click="handleSegmentSwitch('manual')"
                  label="Manual"
                >
                </v-checkbox>
              </div>
            </div>
          </div>
          <div class="h-180 py-3">
            <div class="d-flex gap-10">
              <p class="mt-1 subElementsWidth">Primary Segment</p>
              <div class="w-200px">
                <v-select
                  dense
                  class="mr-3"
                  v-model="preference.primarySegment"
                  placeholder="Select Landing Page"
                  :items="primaryList"
                  :disabled="segmentAutomatic"
                  outlined
                  autofocus
                  :menu-props="{ bottom: true, offsetY: true }"
                ></v-select>
              </div>

              <!-- Check for both CDS and BCD -->
            </div>
            <div class="d-flex gap-10">
              <p class="mt-1 subElementsWidth">Secondary Segment</p>
              <div class="w-200px">
                <v-select
                  dense
                  class="mr-3"
                  v-model="preference.secondarySegment"
                  placeholder="Select Landing Page"
                  :items="getSecondaryList()"
                  :disabled="segmentAutomatic"
                  outlined
                  autofocus
                  :menu-props="{ bottom: true, offsetY: true }"
                ></v-select>
              </div>
            </div>
            <div class="d-flex gap-10">
              <p class="mt-1 subElementsWidth">Third Preference</p>
              <div class="w-200px">
                <v-select
                  dense
                  class="mr-3"
                  v-model="preference.thirdPreference"
                  placeholder="Select Landing Page"
                  :disabled="segmentAutomatic"
                  :items="getThirdPrefList()"
                  outlined
                  autofocus
                  :menu-props="{ bottom: true, offsetY: true }"
                ></v-select>
              </div>
            </div>
            <div class="d-flex gap-10">
              <p class="mt-1 subElementsWidth">Fourth Preference</p>
              <div class="w-200px">
                <v-select
                  dense
                  class="mr-3"
                  v-model="preference.fourthPreference"
                  placeholder="Select Landing Page"
                  :disabled="segmentAutomatic"
                  :items="getFourthPrefList()"
                  outlined
                  autofocus
                  :menu-props="{ bottom: true, offsetY: true }"
                ></v-select>
              </div>
            </div>
          </div>
          <div class="d-flex gap-10 justify-end pr-8">
            <v-btn
              :loading="loading"
              height="40"
              depressed
              type="submit"
              color="primary"
              class="fsize12 text-capitalize"
              @click="setSegments()"
              >Submit</v-btn
            >
          </div>
        </div>
      </v-card>
    </section>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";

export default {
  data: () => ({
    sendPaymentsType: "automatic",
    // segmentAutomatic: true,
    // segmentManual: false,
    // paymentAutomatic: true,
    // paymentManual: false,
    segmentType: "automatic",
    // icclSwitch: false,
    // backOfficeSwitch: false,
    // rmsSwitch: false,
    preference: {
      primarySegment: "",
      secondarySegment: "",
      thirdPreference: "",
      fourthPreference: "",
    },

    // : [
    //   {
    //     text: "NSE_FO",
    //     value: "NSE_FO",
    //   },
    //   {
    //     text: "NSE_CASH",
    //     value: "NSE_CASH",
    //   },
    //   {
    //     text: "CD_NSE",
    //     value: "CD_NSE",
    //   },
    //   {
    //     text: "MCX",
    //     value: "MCX",
    //   },
    // ],
  }),
  methods: {
    toggleIccl() {
      this.icclSwitch = !this.icclSwitch;
    },
    toggleBackOffice() {
      this.backOfficeSwitch = !this.backOfficeSwitch;
    },
    toggleRms() {
      this.rmsSwitch = !this.rmsSwitch;
    },
    getSecondaryList() {
      return this.primaryList?.filter(
        (el) => el.text != this.preference.primarySegment
      );
    },
    getThirdPrefList() {
      return this.primaryList?.filter(
        (el) =>
          el.text != this.preference.primarySegment &&
          el.text != this.preference.secondarySegment
      );
    },
    getFourthPrefList() {
      return this.primaryList?.filter(
        (el) =>
          el.text != this.preference.primarySegment &&
          el.text != this.preference.secondarySegment &&
          el.text != this.preference.thirdPreference
      );
    },
    changePrimarySegment(val, key) {
      //  this.primarySegment = val;
      for (let item in this.preference) {
        if (this.preference.hasOwnProperty(item)) {
          if (key != item) {
            this.preference[item] == val
              ? (this.preference[item] = this.preference[key])
              : "";
          }
        }
      }

      this.preference[key] = val;
    },
    changeSecondarySegment(val) {
      this.secondarySegment = val;
    },
    changeThirdPreference(val) {
      this.thirdPreference = val;
    },
    changeFourthPreference(val) {
      this.fourthPreference = val;
    },
    setPayments() {
      this.$store.dispatch("controls/setPaymentControls");
    },
    setSegments() {
      // this.$store.dispatch("controls/setSegments");
    },
    handlePaymentSwitch(value) {
      this.paymentAutomatic && value == "auto"
        ? (this.paymentManual = false)
        : "";
      this.paymentManual && value == "manual"
        ? (this.paymentAutomatic = false)
        : "";
    },
    handleSegmentSwitch(value) {
      this.segmentAutomatic && value == "auto"
        ? (this.segmentManual = false)
        : "";
      this.segmentManual && value == "manual"
        ? (this.segmentAutomatic = false)
        : "";
    },
  },
  computed: {
    ...mapState("controls", ["paymentControlStatus","paymentSegmentStatus"]),
    ...mapGetters({
      loading: "getLoader",
    }),
    icclSwitch: {
      get() {
        return this.paymentControlStatus.ICCL;
      },
      set(value) {
        this.$store.state.controls.paymentControlStatus.ICCL = value;
      },
    },
    backOfficeSwitch: {
      get() {
        return this.paymentControlStatus.BO;
      },
      set(value) {
        this.$store.state.controls.paymentControlStatus.BO = value;
      },
    },
    rmsSwitch: {
      get() {
        return this.paymentControlStatus.RMS;
      },
      set(value) {
        this.$store.state.controls.paymentControlStatus.RMS = value;
      },
    },
    paymentAutomatic: {
      get() {
        return this.paymentControlStatus.isAutomatic;
      },
      set(value) {
        this.$store.state.controls.paymentControlStatus.isAutomatic = value;
      },
    },
    paymentManual: {
      get() {
        return this.paymentControlStatus.isManual;
      },
      set(value) {
        this.$store.state.controls.paymentControlStatus.isManual = value;
        //this.setPayments();
      },
    },
    segmentAutomatic:{
      get() {
        return this.paymentSegmentStatus.isAutomatic;
      },
      set(value) {
        this.$store.state.controls.paymentSegmentStatus.isAutomatic = value;
        //this.setPayments();
      },
    },
    segmentManual: {
      get() {
        return this.paymentSegmentStatus.isManual;
      },
      set(value) {
        this.$store.state.controls.paymentSegmentStatus.isManual = value;
        //this.setPayments();
      },
    },
    primaryList:{
      get(){
        return this.paymentSegmentStatus.seg
      }
    }
    

  },
  async created() {
    await this.$store.dispatch("controls/getPaymentsControls");
    await this.$store.dispatch("controls/getPaymentSegments").finally(()=>{
      if(this.paymentSegmentStatus && this.paymentSegmentStatus.seg ){
        let seg = this.paymentSegmentStatus.seg
        this.preference.primarySegment = seg[0] ? seg[0] : ''
        this.preference.secondarySegment = seg[1] ? seg[1] : ''
        this.preference.thirdPreference = seg[2] ? seg[2] : ''
        this.preference.fourthPreference = seg[3] ? seg[3] : ''
      }
    })
    //this.$store.dispatch("controls/getTppSwitchStatus");

    // this.$store.dispatch("controls/getSchedulerSwitch");
  },
};
</script>
<style>
.controlsBorderColor {
  border-color: black;
}
.controlsRadio .v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  font-size: 12px !important;
}
.controlsRadio .v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.controlsRadio .v-input--selection-controls__input {
  width: 16px !important;
  height: 16px !important;
}

.controlsRadio .v-input--selection-controls__input {
  margin-right: 20px !important;
}

.controlsRadio .v-input--radio-group.v-input--radio-group--row .v-radio {
  margin-right: 12px !important;
}

.gap-15 {
  gap: 40px;
}
.gap-10 {
  gap: 20px;
}
.subElementsWidth {
  min-width: 150px;
}
.h-20 {
  height: 20px;
}
.w-25 {
  width: 30% !important;
}
.controlsSwitch
  .v-input--switch.v-input--dense.v-input--switch--inset
  .v-input--switch__track {
  height: 16px !important;
  width: 38px !important;
  top: calc(50% - 12px);
  left: -3px;
}
.h-180 {
  height: 280px !important;
}
.controlsSwitch .v-input--switch.v-input--dense .v-input--switch__thumb {
  width: 12px !important;
  height: 12px !important;
}
.v-list-item {
  min-height: 10px !important;
}
.v-list-item__content {
  padding: 4px 0px !important;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.w-40 {
  width: 47%;
}
.w-200px {
  width: 200px !important;
}
</style>
